import React, { Component } from 'react';
import BoardObj from '../../data_objects/boardObject';

export default class BoardCard extends Component {
    render() {
        return (
<div className="main-area">
            {BoardObj.map((data) => { 
                return (
            <div class="centered">

                <section class="cards">
                    
                    <article class="card">
                        <a href="#">
                            <picture class="thumbnail">
                                <img src="http://www.abbeyjfitzgerald.com/wp-content/uploads/2017/02/image-example-01.jpg" alt="A banana that looks like a bird"/>
                            </picture>
                            <div class="card-content">

                                <h2>{data.name}</h2>
                                <p>{data.title}</p>
                            </div>
                        </a>
                    </article>

                </section>
            
            </div>
        )
            })}
    </div>
        )
    }
}
