import React, { Component } from 'react'
import './aboutStyles.css'
import AlanPresAward from '../../Images/AlanPresidentsAward.png';


export default class AboutCard extends Component {
    render() {
        return (
            <div className='about-body'>
            <div className="about-card">
            <div className="about-header">
            <h2>Alan Anderson</h2>
            </div>
            <div className="about-row">
            <div className="avatar-container">
                <div className="about-photo">
                <img className="about-img" src={AlanPresAward} height="250" width="250"/>
                <p>
                Alan Anderson was a champion in every sense of the word.  The hallmark of Alan’s life was his commitment to his family and the people who worked with him at Mahwah Honda and Acura of Ramsey.  His passion, energy, drive and vision led Alan to remarkable successes throughout his life in business, on the baseball field and in his community and gained him the respect and admiration of everyone that knew him.  
                <br/>               
                <br/>
                A lifelong resident of New Jersey, Alan grew up in New Milford and was the third child among five children.  Alan graduated from New Milford High in 1973 where he was a standout athlete in baseball and basketball and was immensely popular with both classmates and teachers.  After high school, Alan started working in the automobile business and continued his baseball career, playing center field and batting leadoff with the Emerson-Westwood Merchants in a semi-pro summer league for several years.  Alan’s abilities on the baseball field drew the attention of the varsity baseball coach at William Paterson College (now called William Paterson University), and he enrolled as a full-time student at the College in 1978.  
                <br/>               
                <br/>
                At William Paterson, Alan excelled in the classroom and on the baseball field.  He received numerous awards for his play on the field.  Alan was co-captain of the baseball team his sophomore, junior and senior years.  He was a member of the New Jersey All-Star Team his junior and senior years.  In his senior year. Alan was named to All-Conference, All-State and Academic All-America teams.  
                <br/>               
                <br/>
                Alan graduated from William Paterson in 1981 with a bachelor’s degree in Business Administration.  In recognition of his outstanding college baseball career, Alan was inducted into the William Paterson College Athletic Hall of Fame in 1990.  Alan had a career batting average of .344, and his career 53 stolen bases and 37 walks put him in the top ten for William Paterson baseball all-time statistical records.
                <br/>               
                <br/>
                After college graduation, Alan started employment with the Mahwah Honda and Acura of Ramsey car dealerships.  With a relentless attention to detail, strong work ethic and unwavering ethical standards, Alan became the trusted right hand for the owner of the car dealerships.  By 1996, Alan became dealer partner for Mahwah Honda, and by 1999, he had purchased both dealerships.  
                <br/>               
                <br/>
                Throughout the twenty plus years of Alan’s ownership, both dealerships won countless awards. Mahwah Honda earned eleven Council of Excellence Awards, which recognizes outstanding Honda Financing Departments that have demonstrated exemplary customer service.  Acura of Ramsey is the longest running recipient of the prestigious “Jersey Cup” awarded by Acura to the highest-ranking dealer for customer satisfaction.
                <br/>               
                <br/>
                Alan personally, and through Mahwah Honda and Acura of Ramsey, supported many charities and organizations.  He was particularly generous to Unico, a charity that promotes the heritage and culture of Italian Americans, along with several police departments in Bergen County and the Bergen County Prosecutors Office, Toys for Tots and numerous others.
                <br/>               
                <br/>
                Alan was extremely proud of his family.  He was always very close with his brother and three sisters and was devoted to them his whole life.  However, Alan took a special interest in his nieces and nephews.  They were the sun and moon to him. 
                <br/>               
                <br/>
                Alan’s unique gift was his ability to treat everyone with respect and to make all feel valued, young or old, well-to-do or in need, popular or not.   Alan will always be remembered for his joy for life, his never give up spirit and a smile that could warm a person on the coldest day.  The Foundation was established in Alan’s name to continue Alan’s good deeds and continue to give his gifts to others.

                </p>
                </div>
            </div>
            {/* <div className="about-details-container"> */}
                <div className="about-content">
                {/* <h3>Alan Anderson</h3> */}
                </div>
            {/* </div> */}
            </div>
            </div>
    </div>
        )
    }
}
