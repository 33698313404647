import React, { Component } from 'react'
import EventsPageCard from '../events/eventsPageCard';
import axios from 'axios';
import '../events/eventsCard.css'

export default class EventsPage extends Component {
    render() {
        return (

            <React.Fragment>
            <div className="events-page-container">
                <p> Fundraising and events are to honor Alan, as well as raise money for scholarships and cancer research that the Foundation will be supporting financially. 
                    The Alan J. Anderson Foundation Golf Outing proceeds will be used to provide scholarships to New Milford High School and William Paterson University students, 
                    and grants to organizations to support continued investment in research and treatment of cancer.
                </p>
            </div>

            <div className="upcoming-events-section2">
            <div className="up-events2">
                <h1>Upcoming Events</h1>
             </div>
             </div>

            <EventsPageCard/>

            </React.Fragment>

        )
    }
}
