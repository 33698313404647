import React, { Component } from 'react'
import BoardObj from '../../data_objects/boardObject';
import { width, textAlign } from '@material-ui/system';

export default class boardCtwo extends Component {
    
    render() {

        var sectionStyle = {
            backgroundImage: "url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/210284/flex-1.jpg",
            maxWidth: "75%",
            marginLeft: "12.5%"
          }

        return (
            <React.Fragment>

                    <div className="band">
                    { BoardObj.map((data) => {
                        return(
                    <div className="item-2">
                            <a class="card">
                            <div class="thumb" style={{ backgroundImage: `url(${data.pic}` , maxWidth: "75%", marginLeft: "12.5%", maxHeight: "100%" }}></div>
                            <article>
                                <h3>{data.name}</h3>
                                <span>{data.title}</span>
                            </article>
                            </a>
                    </div>
                        )
                        })
                    }

                </div>
            </React.Fragment>
        )
    }
}
