import React, { Component } from 'react';
import styled from 'styled-components';

export default class Card extends Component {
    render() {

const Title = styled.h2`
  color: 'black';
  font-weight: 300;
  text-align: center;
`
const Date = styled.div`
  color: #ccc;
  font-weight: 300;
  margin: 6px 0;
`
const Description = styled.p`
  color: 'black';
  font-weight: 300;
`

        return (
            <div style={{ color: 'black', padding: '30px', margin: '40px', backgroundColor: 'cream', border: 'lightgrey 2px solid', justifyContent:'center', marginLeft: '25%', flex: '1', maxWidth: '50%' }}>
            <Title>About the foundation</Title>
            <Date>founded:</Date>
            <Description>
             AJA Foundation AJA Foundation  AJA Foundation  AJA Foundation AJA Foundation AJA Foundation AJA Foundation 
             AJA Foundation AJA Foundation AJA Foundation AJA Foundation AJA Foundation AJA Foundation AJA Foundation 
             AJA Foundation AJA Foundation AJA Foundation AJA Foundation AJA Foundation AJA Foundation AJA Foundation 
             AJA Foundation AJA Foundation AJA Foundation AJA Foundation AJA Foundation AJA Foundation AJA Foundation AJA Foundation 
             AJA Foundation AJA Foundation AJA Foundation AJA Foundation AJA Foundation AJA Foundation AJA Foundation 
    
            </Description>
          </div>
        )
    }
}
