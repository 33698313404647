import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"
import NavBar from './components/header/navBar';
import About from './components/About/About';
import HomePage from './components/home/home';
import Footer from './components/footer/footer'
import FundPage from './components/pages/FundraiserPage';
import DonationPage from './components/pages/donatePage';
import Events from './components/pages/eventsPage';
import Card from "@material-ui/core/Card";
import SignUpForm from './components/form/signup-form';
import SignUpFormMUI from './components/form/materialSignUpForm';
import DonationsForm from './components/form/donation-form';
import DonationsFormMUI from './components/form/materialDonationsForm';
import ViewDonors from './components/pages/ViewDonors';
import './components/header/navBar.css';
import './App.css';

export default class App extends Component {

  state = {
    data: null,
    d2: null
  };

  render() {
  
    return (
      <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
        <Route path="/donations">
        <div className="wrapper">
            <NavBar/>
            <DonationsFormMUI/>
            <div className='page-main-filler' style={{'backgroundColor': '#f8f8f8'}}></div> 
            <Footer/>
        </div>
        </Route>
        <Route path="/sign-in">
        <div className="wrapper">
            <NavBar/>
            <ViewDonors/>
            {/* <Footer/> */}
        </div>
        </Route>
        <Route path="/golf-outing">
        <div className="wrapper">
            <NavBar/>
            <SignUpFormMUI/>
            <div className='page-main-filler' style={{'backgroundColor': '#f8f8f8'}}></div> 
            <Footer/>
        </div>
        </Route>
        <Route path="/fund">
        <div className="wrapper">
            <NavBar/>
            <Events/>
            <div className='page-main-filler'></div> 
            <Footer/>
        </div>
        </Route>
          <Route path="/about">
          <div className="wrapper">
            <NavBar/>
            <About />
            {/* <div className='page-main-filler'></div>  */}
            <Footer/>
          </div>
          </Route>
          <Route path="/events">
        <div className="wrapper">
            <NavBar/>
            <Events/>
            <div className='page-main-filler'></div> 
            <Footer/>
        </div>
        </Route>
          {/* <Route path="/donate">
          <div className="wrapper">
            <NavBar/>
            <DonationPage/>
            <div className='page-main-filler'></div> 
            <Footer/>
          </div>
          </Route> */}
          <Route path="/">
            <div className="wrapper">
              <NavBar/> 
              {/* <div className='sponsors-banner'>
                <p className='sponsors-banner-msg'> 
                Thank you to our sponsors!</p>
              </div> */}
              <HomePage/>
              <Footer/>
            </div>
             {/* <HomePage/> */}
          </Route>
        </Switch>
        <div>{this.state.data}</div>
        <div>{this.state.d2}</div>
      </div>
    </Router>
    );
  }

}
