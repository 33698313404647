import React, { Component } from 'react';
import HamMenu from '../header/hamburgerMenu/mobileMenu';

export default class navBar extends Component {

  state = {
    open: '',
    showHamburger: ''
  }

  handleClick() {
    this.setState({
        open: !this.state.open
    });
}

  render() {
        return (

<React.Fragment>
  <header className="page-header">
    <nav>
      <h2 className="logo"><a href= '/'>Alan J. Anderson Foundation</a></h2>
      <ul>
      <li>
          <a href="/">Home</a>
       </li>
        <li>
          <a href="/about">About</a>
        </li>
        <li>
          <a href="/events">Events</a>
        </li>
      </ul>

      <button className="cta-contact"><a href='/donations'>Donate</a></button>


  <div className='hamburger-menu'>
     <HamMenu/>
  </div>

    </nav>
  </header>
</React.Fragment>
        )
    }
}
