import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Column, Row } from 'simple-flexbox';
import Card from '../About/Card';
import calanderIcon from '../../icons/icon.png';
import EventCard from './eventCard';
import EventTwoCard from './event-2Card';
import './homeStyles.css';
import EventsObj from '../../data_objects/eventsObject';
import AlanImage from '../../Images/AlanImage10.png'
import axios from 'axios';
import AJAlogo from '../../Images/alan-j-anderson-foundation-2.svg';


export default class HomePage extends Component {
    render() {
        return (

<React.Fragment>
  <main className="page-main">
    <div>
      <h1>Alan J. Anderson Foundation</h1>
      {/* <img height={300} src={AJAlogo}/> */}
    </div>
  </main>
    <div className='lower_sec'>
      {/* <Card/> */}
      <div className='aboutPage-container'>
        <p className= 'about-par'>
        The Alan J. Anderson Foundation was created to remember the life of Alan J. Anderson and to continue his good deeds. 
        The Foundation is a public charity under Section 501(c)(3) of the Internal Revenue Code. 
        The financial support for the Foundation comes from Alan’s friends and family and other businesses, organizations and individuals.
        <br/>
        <br/>
        The Foundation promotes educational opportunities by awarding college scholarships to deserving student athletes.  
        The Foundation also recognizes the need for the continued investment in research and treatment of cancer and makes grants to organizations which are leaders in the fight against cancer.
        </p>

      <div className="homepage-img-container">
        <img src={AlanImage} height='300' width='300' />
      </div>

      </div>
    </div>

    <div className="upcoming-events-section">
    <div className="up-events">
    <img src={calanderIcon}/>
      <h1>Upcoming Events</h1>
    </div>
        {/* <EventCard
        eventsObject={EventsObj}
        /> */}

        <EventTwoCard
          eventsObject={EventsObj}
        />
    </div>
  </React.Fragment>

        )
    }
}
