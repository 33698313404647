import NewYork_CC_Img from '../Images/NewYork_CC.png';
import RamseyDH from '../Images/RamseyDiningHall.gif';

const eventsObj = [
    {
            "name": "Golf Outing",
            "date": { "Month": "September", "Day": "23", 
            "Year": 2024, "Time": "1PM" },
            "Location": "103 Brick Church Road, New Hempstead, NY 10977",
            "Description": "New York Country Club",
            "eventOptions": {
                "Golfing": { "Price": "$350", "Includes": [ "Golfing", "BBQ Lunch", "Buffet Dinner", "Open Bar" ]},
                "Dinner": { "Price": "$120", "Includes": [ "Buffet Dinner", "Open Bar" ]}
            },
            "picSrc": NewYork_CC_Img,
            "IsOver": false,
            "eventNum": 0,
            "live": true
    },
    {
            "name": "Fundraising Dinner",
            "date": { "Month": "September", "Day": "23", 
            "Year": 2024, "Time": "6PM" },
            "Location": "103 Brick Church Road, New Hempstead, NY 10977",
            "Description": "New York Country Club Ballroom",
            "eventOptions": {
                "Golfing": {},
                "Dinner": {}
            },
            "picSrc": RamseyDH,
            "IsOver": false,
            "eventNum": 1,
            "live": true
    },
    
]

export default eventsObj;