import React, { Component } from 'react';
import axios from 'axios';
import { asCurrencyValue, purePriceValue } from '../../utils/pipes';
import './form.css';

export default class SignUpForm extends Component {

    state = {
        first: '',
        last: '',
        company: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        zip: '',
        stateOf: "NJ",
        message: '',
        thankYou: '',
        eventType: '',
        amount: '',
        creditCard: '',
        expirationDate: '',
        securityCode: '',
        apiLoginId: '2tQMHyz323a',
        apiKey: '7Rp73M2q49kQTRxE',
        transactionResponse: ''
      }

      purchaseTicket = (event) => {
        this.setState({ transactionResponse: 'Loading'})

        event.preventDefault();
       const sendPaymentPayload = {
            // donation_apiLoginId: "38EmM77mQ",
            // donation_apiKey: "9aPsK76ve476xX68",
            donation_name: this.state.first,
            donation_last: this.state.last,
            donation_email: this.state.email,
            donation_phone: this.state.phone,
            donation_amount: this.state.amount,
            donation_paid: true,
            donation_address: this.state.address,
            donation_city: this.state.city,
            donation_state: this.state.stateOf,
            donation_zipCode: this.state.zip,
            donation_message: `This purchase is for a ticket for the: ${this.state.eventType}`,
            donation_anonymous: false,
            event_type: "Donation",
            card_number: this.state.creditCard,
            expiration_date: this.state.expirationDate,
            card_code: this.state.securityCode,
            company_name: this.state.company
          }

          axios.post('/api/donationz/donate', sendPaymentPayload).then((result) => {
            this.setState({ transactionResponse: result.data })
          }).catch((error) => {
             console.log('err', error);
          })

      }
      handleInputChange = event => {
        // Pull the name and value properties off of the event.target (the element which triggered the event)
        const { name, value } = event.target;
        // Set the state for the appropriate input field
        this.setState({
          [name]: value
        });
      };

      handleDropDownChange = event => {
        this.setState({ stateOf: event.target.value });
      }

      handleRadioChange = (event) => {
        this.setState({
          eventType: event.target.value,
        });
      }

      handleErrorMessage = () => {
        if(this.state.transactionResponse === 'Error') {
            return (
                <div style={{"color": "red", "padding": "1%"}}>
                Error processing your payment. Please check your credentials and try again.
                </div>
            )
        } else {
            return;
        }
      }

      eventChoices = () => {
        //Golf Dinner
            return (
              <div>
                <li>
                    <label className="input-label" for="amount">Donation Amount: <b>${asCurrencyValue(this.state.amount)}</b></label>
                        <input className="input-input" type="text" id="amount" 
                        placeholder="Please enter your donation amount"
                        name="amount" onChange={this.handleInputChange}
                        required/>
                </li>
                    <li>
                      <label className="input-label" for="creditCard">Card Number</label>
                          <input className="input-input" type="text" id="creditCard" 
                          placeholder="Please enter your card number"
                          name="creditCard" onChange={this.handleInputChange}
                          required/>
                  </li>
                  <li>
                      <label className="input-label" for="expirationDate">Expiration Date</label>
                          <input className="input-input" type="text" id="expirationDate" 
                          placeholder="MM/YYYY"
                          name="expirationDate" onChange={this.handleInputChange} 
                          required/>
                  </li>
                  <li>
                      <label className="input-label" for="securityCode">Security Code</label>
                          <input className="input-input" type="text" id="securityCode" 
                          placeholder="Please enter your Security Code"
                          name="securityCode" onChange={this.handleInputChange} 
                          required/>
                  </li>
  
                  <button className='btn sign-up-form-btn' onClick={(event) => this.purchaseTicket(event)}>Donate</button>
              </div>
            )
  }

    render() {
        return (
        <>
            <section className="signUp-section2">
                <div className="signUp-box">
                <form className="signUp-form">
                    <div className="signUp-inner">
                        <h2>Donations Page</h2>
                        <span>All donations go towards cancer research, and scholarship programs for student athletes</span>
                    </div>
                    <div className='input-fields'>
                        <li>
                            <label className="input-label" for="first-name">First Name</label>
                            <input className="input-input" type="text" id="first-name" 
                            placeholder="Please enter your first name"
                            name="first" onChange={this.handleInputChange}
                            required/>
                        </li>

                        <li>
                            <label className="input-label" for="last-name">Last Name</label>
                            <input className="input-input" type="text" id="last-name" 
                            placeholder="Please enter your last name"
                            name="last" onChange={this.handleInputChange}
                            required/>
                        </li>

                        <li>
                            <label className="input-label" for="company">Company</label>
                            <input className="input-input" type="text" id="company" 
                            placeholder="* Optional *"
                            name="company" onChange={this.handleInputChange}/>
                        </li>

                        <li>
                            <label className="input-label" for="email">Email</label>
                            <input className="input-input" type="email" 
                            id="email" placeholder="Please enter your email"
                            name="email" onChange={this.handleInputChange}
                            required/>
                        </li>

                        <li>
                            <label className="input-label" for="phone">Phone Number</label>
                            <input className="input-input" type="text" 
                            id="phone" 
                            name="phone" onChange={this.handleInputChange}
                            placeholder="Please enter your phone number"
                            required/>
                        </li>

                        <li>
                            <label className="input-label" for="phone">Address</label>
                            <input className="input-input" type="text" 
                            id="address" 
                            name="address" onChange={this.handleInputChange}
                            placeholder="Please enter your address"
                            required/>
                        </li>

                        <li className='city-input'>
                            <label className="input-label" for="phone">City</label>
                            <input className="input-input" type="text" 
                            id="city" 
                            name="city" onChange={this.handleInputChange}
                            placeholder="Please enter your city"
                            required/>
                        </li>

                        <li>
                            <label className="input-label" for="phone">Zipcode</label>
                            <input className="input-input" type="text" 
                            id="zip" 
                            name="zip" onChange={this.handleInputChange}
                            placeholder="Please enter your zipcode"
                            required/>
                        </li>

                        <li>
                        <label className="input-label" for="phone">Choose State: {this.state.stateOf}</label>
                        <select className="state-dropdown" onChange={this.handleDropDownChange}>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option selected value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </select>
                        </li>
            { this.state.transactionResponse !== 'Success' ?
                <>
                            {this.eventChoices()}

                    {this.state.transactionResponse === 'Loading' ?
                    <>
                        <h1>Loading...</h1>
                        <div class="spin"></div>
                    </> :
                        <div>
                            {this.handleErrorMessage()}
                        </div>
                    }       
                    </>

           : <div style={{"color":"green", "padding": "2%"}}> 
                <div> Thank you for donating! </div> 
           </div>
           }
                    </div>
                    </form>
                </div>
            </section>
            { this.state.transactionResponse === 'Success' ?
            <div className='page-main-filler' style={{'backgroundColor': 'rgba(9, 54, 24, 0.76)'}}></div> 
            : null }

        </>
        )
    }
}
