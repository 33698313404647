import React, { Component } from 'react';
import axios from 'axios';
import { asCurrencyValue, purePriceValue } from '../../utils/pipes';
import { TextField, Grid, makeStyles, Button, NativeSelect, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import './form.css';

export default class SignUpForm extends Component {

    state = {
        first: '',
        last: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        zip: '',
        stateOf: "",
        message: '',
        thankYou: '',
        eventType: '',
        amount: '',
        creditCard: '',
        expirationDate: '',
        securityCode: '',
        apiLoginId: '2tQMHyz323a',
        apiKey: '7Rp73M2q49kQTRxE',
        transactionResponse: '',
        ticketAmount: 1
      }

      purchaseTicket = (event) => {
        this.setState({ transactionResponse: 'Loading'})

        event.preventDefault();
       const sendPaymentPayload = {
            // donation_apiLoginId: "38EmM77mQ",
            // donation_apiKey: "9aPsK76ve476xX68",
            donation_name: this.state.first,
            donation_last: this.state.last,
            donation_email: this.state.email,
            donation_phone: this.state.phone,
            donation_amount: this.state.amount,
            donation_paid: true,
            donation_address: this.state.address,
            donation_city: this.state.city,
            donation_state: this.state.stateOf,
            donation_zipCode: this.state.zip,
            donation_message: `This purchase is for a ticket for the: ${this.state.eventType}`,
            donation_anonymous: false,
            event_type: this.state.eventType,
            card_number: this.state.creditCard,
            expiration_date: this.state.expirationDate,
            card_code: this.state.securityCode
          }

          axios.post('/api/donationz/donate', sendPaymentPayload).then((result) => {
            this.setState({ transactionResponse: result.data })
          }).catch((error) => {
             console.log('err', error);
          })

      }
      handleInputChange = event => {
        // Pull the name and value properties off of the event.target (the element which triggered the event)
        const { name, value } = event.target;
        // Set the state for the appropriate input field
        this.setState({
          [name]: value
        });
      };  

      handleDropDownChange = event => {
        this.setState({ stateOf: event.target.value });
      }

      //clean this up
      handleTicketAmount = event => {
        const { amount } = this.state;
        this.setState({ ticketAmount: event.target.value,
            amount: JSON.stringify(100 * event.target.value)
        });
      }

      foursomeTicketAmount = event => {
        const { amount } = this.state;
        this.setState({ ticketAmount: event.target.value,
            amount: JSON.stringify(1100 * event.target.value)
        });
      }

      golfTicketAmount = event => {
        const { amount } = this.state;
        this.setState({ ticketAmount: event.target.value,
            amount: JSON.stringify(275 * event.target.value)
        });
      }
        //clean this up

      handleRadioChange = (event) => {
        this.setState({
          eventType: event.target.value,
        });
      }

      handleErrorMessage = () => {
        if(this.state.transactionResponse === 'Error') {
            return (
                <div style={{"color": "red", "padding": "1%"}}>
                Error processing your payment. Please check your credentials and try again.
                </div>
            )
        } else {
            return;
        }
      }

      eventChoices = () => {
        //Golf Dinner
        if(this.state.eventType == 'Golf') { 
          return (
            <div>
            <label className="input-label" for="payment-amount">Payment Amount:<b>${this.state.amount}</b></label>
            <Grid container>
                <Grid className='form-type-1' item sm={6} lg={6} xs={12}>
                        <TextField
                            fullWidth
                            required
                            id="outlined-required"
                            label="Card Number"
                            defaultValue=""
                            variant="outlined"
                            name='creditCard'
                            onChange={this.handleInputChange}
                            />
                    </Grid>

                    <Grid className='form-type-1' item sm={6} lg={6} xs={12}>
                        <TextField
                            fullWidth
                            required
                            id="outlined-required"
                            label="Expiration Date"
                            defaultValue="MM/YYYY"
                            variant="outlined"
                            name='expirationDate'
                            onChange={this.handleInputChange}
                            />
                    </Grid>

                    <Grid className='form-type-1' item sm={6} lg={6} xs={12}>
                        <TextField
                            fullWidth
                            required
                            id="outlined-required"
                            label="Security Code"
                            defaultValue=""
                            variant="outlined"
                            name='securityCode'
                            onChange={this.handleInputChange}
                            />
                    </Grid>

                    <Grid className='form-type-1' item sm={6} lg={6} xs={12}>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        label="How many tickets would you like to purchase?"
                        fullWidth
                        value={this.state.ticketAmount} 
                        onChange={this.golfTicketAmount}
                        variant="outlined"
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                        </TextField>
                    </Grid>
            </Grid>
            <Button id='m-ui-signup-btn' onClick={(event) => this.purchaseTicket(event)}>Sign Up</Button>
            </div>
          )
        } if(this.state.eventType == "Dinner"){
          return (
            <div>
            <label className="input-label" for="payment-amount">Payment Amount:<b>${this.state.amount}</b></label>
            <Grid container>
                <Grid className='form-type-1' item sm={6} lg={6} xs={12}>
                        <TextField
                            fullWidth
                            required
                            id="outlined-required"
                            label="Card Number"
                            defaultValue=""
                            variant="outlined"
                            name='creditCard'
                            onChange={this.handleInputChange}
                            />
                    </Grid>

                    <Grid className='form-type-1' item sm={6} lg={6} xs={12}>
                        <TextField
                            fullWidth
                            required
                            id="outlined-required"
                            label="Expiration Date"
                            defaultValue="MM/YYYY"
                            variant="outlined"
                            name='expirationDate'
                            onChange={this.handleInputChange}
                            />
                    </Grid>

                    <Grid className='form-type-1' item sm={6} lg={6} xs={12}>
                        <TextField
                            fullWidth
                            required
                            id="outlined-required"
                            label="Security Code"
                            defaultValue=""
                            variant="outlined"
                            name='securityCode'
                            onChange={this.handleInputChange}
                            />
                    </Grid>

                    <Grid className='form-type-1' item sm={6} lg={6} xs={12}>
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        label="How many tickets would you like to purchase?"
                        fullWidth
                        value={this.state.ticketAmount} 
                        onChange={this.handleTicketAmount}
                        variant="outlined"
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                        </TextField>
                    </Grid>
            </Grid>
            <Button id='m-ui-signup-btn' onClick={(event) => this.purchaseTicket(event)}>Sign Up</Button>
            </div>
          )
        } if(this.state.eventType == "Foursome"){
            return (
                <div>
                <label className="input-label" for="payment-amount">Payment Amount:<b>${this.state.amount}</b></label>
                <Grid container>
                    <Grid className='form-type-1' item sm={6} lg={6} xs={12}>
                            <TextField
                                fullWidth
                                required
                                id="outlined-required"
                                label="Card Number"
                                defaultValue=""
                                variant="outlined"
                                name='creditCard'
                                onChange={this.handleInputChange}
                                />
                        </Grid>
    
                        <Grid className='form-type-1' item sm={6} lg={6} xs={12}>
                            <TextField
                                fullWidth
                                required
                                id="outlined-required"
                                label="Expiration Date"
                                defaultValue="MM/YYYY"
                                variant="outlined"
                                name='expirationDate'
                                onChange={this.handleInputChange}
                                />
                        </Grid>
    
                        <Grid className='form-type-1' item sm={6} lg={6} xs={12}>
                            <TextField
                                fullWidth
                                required
                                id="outlined-required"
                                label="Security Code"
                                defaultValue=""
                                variant="outlined"
                                name='securityCode'
                                onChange={this.handleInputChange}
                                />
                        </Grid>
    
                        <Grid className='form-type-1' item sm={6} lg={6} xs={12}>
                        <TextField
                            id="outlined-select-currency-native"
                            select
                            label="How many tickets would you like to purchase?"
                            fullWidth
                            value={this.state.ticketAmount} 
                            onChange={this.foursomeTicketAmount}
                            variant="outlined"
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={7}>7</MenuItem>
                                <MenuItem value={8}>8</MenuItem>
                                <MenuItem value={9}>9</MenuItem>
                            </TextField>
                        </Grid>
                </Grid>
                <Button id='m-ui-signup-btn' onClick={(event) => this.purchaseTicket(event)}>Sign Up</Button>
                </div>
            )
          } if(this.state.eventType == "Donation"){
            return (
              <div>
                  <li>
                      <label className="input-label" for="payment-amount">Donation Amount:<b>${asCurrencyValue(this.state.amount)}</b></label>

                      <label className="input-label" for="creditCard">Card Number</label>
                          <input className="input-input" type="text" id="creditCard" 
                          placeholder="Please enter your card number"
                          name="creditCard" onChange={this.handleInputChange}
                          required/>
                  </li>
                  <li>
                      <label className="input-label" for="expirationDate">Expiration Date</label>
                          <input className="input-input" type="text" id="expirationDate" 
                          placeholder="MM/YYYY"
                          name="expirationDate" onChange={this.handleInputChange} 
                          required/>
                  </li>
                  <li>
                      <label className="input-label" for="securityCode">Security Code</label>
                          <input className="input-input" type="text" id="securityCode" 
                          placeholder="Please enter your Security Code"
                          name="securityCode" onChange={this.handleInputChange} 
                          required/>
                  </li>

                  <li>

                    <label className="input-label" for="amount">Donation Amount</label>
                          <input className="input-input" type="text" id="amount" 
                          placeholder="Please enter your donation amount"
                          name="amount" onChange={this.handleInputChange}
                          required/>
                  </li>
  
                  <button className='btn sign-up-form-btn' onClick={(event) => this.purchaseTicket(event)}>Sign Up</button>
              </div>
            )
          }  else {
          return;
        }
  }

  paymentChoices = () => {
      
  }

    render() {
        return (
        <div className="signUp-container">
            <section className="signUp-section2">
                <div className="signUp-box">
                <form className="signUp-form">
                    <div className="signUp-inner">
                        <h2>Sign Up</h2>
                        <span>Please sign up for the Golf Outing</span>
                    </div>
                    <div className='input-fields'>
        <Grid container>
            <Grid className='form-type-1' item sm={6} lg={6} xs={12}>
                <TextField
                    fullWidth
                    required
                    id="outlined-required"
                    label="First Name"
                    defaultValue=""
                    variant="outlined"
                    name='first'
                    onChange={this.handleInputChange}
                    />
            </Grid>

            <Grid className='form-type-1' item sm={6} lg={6} xs={12}>
                <TextField
                    className='form-type-1'
                    required
                    fullWidth
                    id="outlined-required"
                    label="Last Name"
                    defaultValue=""
                    variant="outlined"
                    name='last'
                    onChange={this.handleInputChange}
                    />
            </Grid>

            <Grid className='form-type-1' item sm={12} lg={6} xs={12}>
                <TextField
                    required
                    className='form-type-1'
                    fullWidth
                    id="outlined-required"
                    label="Email"
                    defaultValue=""
                    variant="outlined"
                    name='email'
                    onChange={this.handleInputChange}
                    />
            </Grid>

            <Grid className='form-type-1' item sm={12} lg={6} xs={12}>
                <TextField
                    required
                    className='form-type-1'
                    fullWidth
                    id="outlined-required"
                    label="Phone Number"
                    defaultValue=""
                    variant="outlined"
                    name='phone'
                    onChange={this.handleInputChange}
                    />
            </Grid>

            <Grid className='form-type-1' item sm={12} lg={12} xs={12}>
                <TextField
                    required
                    className='form-type-1'
                    fullWidth
                    id="outlined-required"
                    label="Address"
                    defaultValue=""
                    variant="outlined"
                    name='address'
                    onChange={this.handleInputChange}
                    />
            </Grid>

            <Grid className='form-type-1' item sm={12} lg={6} xs={12}>
                <TextField
                    required
                    className='form-type-1'
                    fullWidth
                    id="outlined-required"
                    label="City"
                    defaultValue=""
                    variant="outlined"
                    name='city'
                    onChange={this.handleInputChange}
                    />
            </Grid>

            <Grid className='form-type-1' item sm={12} lg={6} xs={12}> { /* remember to add the correct dd from mui */ }
                <TextField
                    required
                    className='form-type-1'
                    fullWidth
                    id="outlined-required"
                    label="State"
                    defaultValue=""
                    variant="outlined"
                    name='state'
                    onChange={this.handleInputChange}
                    />
            </Grid>

            <Grid className='form-type-1' item sm={12} lg={6} xs={12}>
                <TextField
                    required
                    className='form-type-1'
                    fullWidth
                    id="outlined-required"
                    label="Zip Code"
                    defaultValue=""
                    variant="outlined"
                    name='zip'
                    onChange={this.handleInputChange}
                    />
            </Grid>

        </Grid>
            { this.state.transactionResponse !== 'Success' ?
                <>
                    <li className="event-types-form">
                            <label for="phone">Which event would you like to sign up for?</label>
                            {/* <label for="phone">Registration for golf is closed. Thank you for the support. Please contact us to be put on a waitlist.</label> */}
                                <div className="k-form-field" value={this.state.selectedValue}>

                                <div className='k-form-inner'>
                                        <input type="radio" name="radio" id="radio2" className="k-radio" value="Foursome" onClick={() => this.setState({ amount: '1100', ticketAmount: 1 })} onChange={this.handleRadioChange}/>
                                        <label className="k-radio-label">Golf Outing: Foursome</label>
                                    </div> 

                                    <div className='k-form-inner'>
                                        <input type="radio" name="radio" id="radio1" className="k-radio" value="Golf" onClick={() => this.setState({ amount: '275', ticketAmount: 1 })} onChange={this.handleRadioChange}/>
                                        <label className="k-radio-label">Golf Outing</label>
                                    </div>

                                    <div className='k-form-inner'>
                                        <input type="radio" name="radio" id="radio2" className="k-radio" value="Dinner" onClick={() => this.setState({ amount: '100', ticketAmount: 1 })} onChange={this.handleRadioChange}/>
                                        <label className="k-radio-label">Dinner</label>
                                    </div>

                                    {/* <div className='k-form-inner'>
                                        <input type="radio" name="radio" id="radio2" className="k-radio" value="Donation" onClick={() => this.setState({ amount: '' })} onChange={this.handleRadioChange}/>
                                        <label className="k-radio-label">Donation</label>
                                    </div> */}
                                </div>
                        </li>

                    {this.state.transactionResponse === 'Loading' ?
                    <>
                        <h1>Loading...</h1>
                        <div class="spin"></div>
                    </> :
                        <div>
                            {this.eventChoices()}
                            {this.handleErrorMessage()}
                        </div>
                    }       
                    </>

           : <div style={{"color":"green", "padding": "2%"}}> 
                <div> Thank you for signing up to attend our event! </div> 
                <div>You will be recieving a confirmation email shortly.</div>
           </div>
           }
                    </div>
                    </form>
                </div>
            </section>
        </div>
        )
    }
}
