import React, { Component } from 'react'
import './Fundraiser.css'
import axios from 'axios';

export default class FundraiserPage extends Component {

    state = {
        first: '',
        last: '',
        email: '',
        phone: '',
        message: '',
        thankYou: '',
        golfPrice: '$250',
        dinnerPrice: '$100',
        eventType: '',
        golfEvents: [ "Golfing", "BBQ Lunch", "Buffet Dinner", "Open Bar" ],
        dinnerEvents: [ "Buffet Dinner", "Open Bar" ]
      }

        // handle any changes to the input fields
  handleInputChange = event => {
    // Pull the name and value properties off of the event.target (the element which triggered the event)
    const { name, value } = event.target;
    // Set the state for the appropriate input field
    this.setState({
      [name]: value
    });

    console.log(this.state)

  };

  handleRadioChange = (event) => {
    this.setState({
      eventType: event.target.value,

    });
  }

  choiceDocs = () => {
        //Golf Dinner
        if(this.state.eventType == 'Golf') { 
          return (
            <div>
              <h3>Golf Outing</h3>
              <div>Price: $250</div>
              <div>
                <h3>Includes:</h3>
                <ul>
                  {this.state.golfEvents.map((items) => {
                    return (
                  <li>{items}</li>
                    )
                    })
                  }
                </ul>

              </div>
            </div>
          )
        } if(this.state.eventType == "Dinner"){
          return (
            <div>
              <h3>Dinner</h3>
              <div>Price: $100</div>
              <div>
                <h3>Includes:</h3>
                <ul>
                  {this.state.dinnerEvents.map((items) => {
                    return (
                  <li>{items}</li>
                    )
                    })
                  }
                </ul>

              </div>
            </div>
          )
        } else {
          return;
        }
  }
      sendData = (event) => {
        const { first, last, email, phone, message, eventType } = this.state

        if( first.length < 2 ){
          event.preventDefault();
          console.log('Please Input correct information')
          this.setState({
            thankYou: 'Please enter your first name'
          }) 
        } if( last.length < 2 ){
          event.preventDefault();
          console.log('Please Input correct information')
          this.setState({
            thankYou: 'Please enter your last name'
          }) 
        } if( email.length < 2 ){
          event.preventDefault();
          console.log('Please Input correct information')
          this.setState({
            thankYou: 'Please enter your email'
          }) 
        } if( phone.length < 2 ){
          event.preventDefault();
          console.log('Please Input correct information')
          this.setState({
            thankYou: 'Please enter your phone number'
          }) 
        } else {
          console.log('Length', first.length)
            event.preventDefault();
            const newObj = {
                "note_name": first,
                "note_last": last,
                "note_email": email,
                "note_phone": phone,
                "note_eventType": eventType,
                "note_completed": true
            }
            try{
                axios.post('/notes/add', newObj).then(() => {
                  console.log('Success!', newObj)
                  this.setState({
                    first: '',
                    last: '',
                    email: '',
                    phone: '',
                    message: '',
                    thankYou: 'Thank you for submitting!'
                  })
                }).then(()=> {
                  setTimeout(() => { 
                    this.setState({
                      thankYou: ''
                    })
                  }, 5000);
                });
            } catch {
                console.log('failure')
            }
        }
      }

      //for form:
      // First, Last, email, send message thank you for rsvp'ing 

    render() {
        return (
<div>
    <div className='header-fund'>RSVP For Golf Tournament</div>
 <div className="container">
  <form className='form-Outter'>
    <ul className="flex-outer">
      <li>
        <label for="first-name">First Name</label>
        <input type="text" id="first-name" 
        placeholder="Enter your first name here"
        name="first" value={this.state.first} 
        onChange={this.handleInputChange}
        required/>
      </li>

      <li>
        <label for="last-name">Last Name</label>
        <input type="text" id="last-name" 
        placeholder="Enter your last name here"
        name="last" value={this.state.last} 
        onChange={this.handleInputChange}
        required/>
      </li>

      <li>
        <label for="email">Email</label>
        <input type="email" 
        id="email" placeholder="Enter your email here"
        name="email"
        value={this.state.email} 
        onChange={this.handleInputChange}
        />
      </li>

      <li>
        <label for="phone">Phone</label>
        <input type="tel" 
        id="phone" 
        name="phone"
        placeholder="Enter your phone here"
        value={this.state.phone} 
        onChange={this.handleInputChange}
        />
      </li>

      <li>
        <label for="phone">Which One Will You Be Attending?</label>
        <br/>
            <div className="k-form-field" value={this.state.selectedValue}>
              <input type="radio" name="radio" id="radio1" className="k-radio" value="Golf" onChange={this.handleRadioChange}/>
              <label className="k-radio-label">Golf Outing</label>

              <input type="radio" name="radio" id="radio2" className="k-radio" value="Dinner" onChange={this.handleRadioChange}/>
              <label className="k-radio-label">Dinner</label>
            </div>
      </li>


      <div>
              {this.choiceDocs()}
            </div>

      <li>
        {/* <button type="submit" onClick={()=> this.sendData()}>Submit</button> */}
        <button onClick={(event)=> this.sendData(event)}>Submit</button>
      </li>
    </ul>

    <div className='thank-you'>{this.state.thankYou}</div>
  </form>
</div>
            {/* <div className='FormBox'>
                <form className='signupForm'>
                <label>First: </label>
                <input type="first"
                        type='text'
                        name="first"   
                        value={this.state.first} 
                        onChange={this.changeNameHandler} 
                />
                <br/>
                <label>Last: </label>
                <input type="last" 
                        type='text'
                        name="last"   
                        value={this.state.last} 
                        onChange={this.changeLastHandler} 
                />
                <br/>
                <label>Email: </label>
                <input type="email" 
                        type='text'
                        name="email"   
                        value={this.state.email} 
                        onChange={this.changeEmailHandler} 
                />
                <br/>

                </form>
            </div> */}
      </div>
        )
    }
}
