import React, { Component } from 'react';
import '../private/ViewDonors.css';
import axios from 'axios';

export default class ViewDonors extends Component {

    state = {
        eventType: 'golfers',
        eventData: '',
        username: '',
        password: '',
        eStatus: false,
        errorMessage: ''
    }

    handleInputChange = event => {
        // Pull the name and value properties off of the event.target (the element which triggered the event)
        const { name, value } = event.target;
        // Set the state for the appropriate input field
        this.setState({
          [name]: value
        });
      };

    getInfoForEvent = async (type) => {
        await axios.get(`api/donationz/${type}`).then((etype) => {
            this.setState({ eventData: etype.data})
        })
    }

    loginOrReject = () => {
        const { username, password } = this.state;
        if( username === 'AlanJAndersonFoundation@gmail.com' && password === 'AcuraHonda361224') {
            this.setState({ eStatus: true })
            this.getInfoForEvent('golfers');
            console.log('Logged in')
        } else {
            console.log('rejected')
            this.setState({ errorMessage: 'Invalid Credentials please try again...' })
        }
    }

    showRealData = () => {
        const { eventData, eventType, errorMessage } = this.state;
        return (
            <div className="private-section">
            <h1>Ticket Purchases and Donations</h1>

            <div className='private-button-list'>
                <button onClick={() => this.setState({ eventType: 'golfers'}) & this.getInfoForEvent('golfers') }>Golfers</button>
                <button onClick={() => this.setState({ eventType: 'foursome'}) & this.getInfoForEvent('foursome')}>Foursome</button>
                <button onClick={() => this.setState({ eventType: 'dinner'}) & this.getInfoForEvent('dinner')} >Dinner Guests</button>
                <button onClick={() => this.setState({ eventType: 'donors'}) & this.getInfoForEvent('donors')}>Donations</button>
            </div>
            <div className ='private-section-golf'>
                <h3 className=''>{eventType}</h3>
                {eventData ?
                    eventData.map(i => {
                        return(
                        <ul className="private-section-golf-list">
                            <li><b>Name:</b> {i.donation_name  +  i.donation_last}</li>
                            <li><b>Email:</b> {i.donation_email}</li>
                            <li><b>Phone:</b> {i.donation_phone}</li>
                            <li><b>Purchase Date:</b> {i.date}</li>
                            <li><b>Amount Paid:</b> ${i.donation_amount}</li>
                        <li>{eventType === 'dinner' ? <b>Tickets Purchased: {i.donation_amount / 100}</b> : null}</li>
                        </ul>
                        )
                    })
               : 'Loading...' }
            </div>
        </div>
        )
    }

    render() {
        const { eStatus, errorMessage } = this.state;
        return (
            <div>
                {!eStatus ?
                <>
                <h1 className="private-signin-h1"> Sign In Please </h1>

                <div class="sign-in-container">
                    <label for="username"><b>Username</b></label>
                    <input className="private-input" type="text" placeholder="Enter Username" name="username" 
                    onChange={this.handleInputChange} required/>

                    <label for="password"><b>Password</b></label>
                    <input className="private-input" type="password" placeholder="Enter Password" name="password"
                    onChange={this.handleInputChange} required/>

                    <button onClick={() => this.loginOrReject()} className="private-button2">Login</button>
                <div className='private-error'>{errorMessage}</div>
                    <label>
                    </label>
                </div>
                </>
                : this.showRealData() }
            </div>
        )
    }
}
