import React, { Component } from 'react';
import AcuraRamseyLogo from '../../Images/sponsorLogo/AcuraofRamsey_Logo.png';
import AutohausLogo from '../../Images/sponsorLogo/Autohaus_Logo.jpg';
import BNCEstates from '../../Images/sponsorLogo/BNCEstates_Logo.png';
import CCBF from '../../Images/sponsorLogo/CCBF_Logo.PNG';
import ConnectOneBank from '../../Images/sponsorLogo/ConnectOneBank_Logo.jpg';
import DVSLogo from '../../Images/sponsorLogo/DVS_Logo22.png';
import GlennAndersonAirLogo from '../../Images/sponsorLogo/GlennAndersonAir_Logo.png';
import HondaFinancialServices from '../../Images/sponsorLogo/HondaFinancialServices_Logo.gif';
import KEAAdvertising from '../../Images/sponsorLogo/KEAAdvertising_Logo.jpg';
import LaurenAndersonAssociates from '../../Images/sponsorLogo/LaurenAndersonAssociates_Logo.bmp';
import MahwahHondaLogo from '../../Images/sponsorLogo/MahwahHonda_Logo.jpg';
import ParkRidgeAnimal from '../../Images/sponsorLogo/ParkRidgeAnimal_Logo2.jpeg';
import PNCBank from '../../Images/sponsorLogo/PNCBank_Logo.jpg';
import RMG from '../../Images/sponsorLogo/RMG_Logo.jpg';
import ShutterbugPhoto_Booth from '../../Images/sponsorLogo/ShutterbugPhoto_Booth_Logo.jpg';
import WDI2 from '../../Images/sponsorLogo/WDI2_Logo.png';
import Withum from '../../Images/sponsorLogo/Withum_Logo.png';
import CohnReznick from '../../Images/sponsorLogo/cohnreznick.png';
import './aboutStyles.css';


export default class SponsorsPageTwo extends Component {

    returnTheLogos = () => {
        let arrLogo = [
        AcuraRamseyLogo, 
        MahwahHondaLogo, 
        //cohreznik
        ConnectOneBank, 
        PNCBank, 
        AutohausLogo, 
        Withum,
        HondaFinancialServices, 

        RMG, 
        CCBF, 

        GlennAndersonAirLogo,
        ParkRidgeAnimal, 
        BNCEstates,
        LaurenAndersonAssociates, 
        KEAAdvertising, 
        DVSLogo, 
        ShutterbugPhoto_Booth, 
        WDI2
    ]
}

    render() {
        return (
            <React.Fragment>

            <div className='sponsors-container'>
                    <div>
                        <h1> Thank You to Our Sponsors</h1>
                    </div>

                    <p>On behalf of the Alan J. Anderson Foundation, we would like to thank all of you- We are  grateful for the support and generosity that all of our sponsors have provided.</p>

                 <div className='sponsors-page-container'>
                    <div className='sponsors-f-1'> 
                        <img className='sponsorsImg1' src={MahwahHondaLogo}/>
                    </div>

                    <div className='sponsors-f-1'> 
                    <img className='sponsorsImg1' src={AcuraRamseyLogo}/>
                    </div>
                </div>

                <div className='sponsors-page-container'>
                    <div className='sponsors-f-1'> 
                        <img className='sponsorsImg1' src={CohnReznick}/>
                    </div>

                    <div className='sponsors-f-1'> 
                    <img className='sponsorsImg1' src={ConnectOneBank}/>
                    </div>
                </div>

                <div className='sponsors-page-container'>
                    <div className='sponsors-f-1'> 
                        <img className='sponsorsImg1' src={PNCBank}/>
                    </div>

                    <div className='sponsors-f-1'> 
                    <img className='sponsorsImg1' src={AutohausLogo}/>
                    </div>
                </div>

                <div className='sponsors-page-container'>
                    <div className='sponsors-f-1'> 
                        <img className='sponsorsImg1' src={Withum}/>
                    </div>

                    <div className='sponsors-f-1'> 
                    <img className='sponsorsImg1' src={HondaFinancialServices}/>
                    </div>
                </div>

                <div className='sponsors-page-container'>
                    <div className='sponsors-f-1'> 
                        <img className='sponsorsImg1' src={RMG}/>
                    </div>

                    <div className='sponsors-f-1'> 
                    <img className='sponsorsImg1' src={CCBF}/>
                    </div>
                </div>

                <div className='sponsors-page-container'>
                    <div className='sponsors-f-1'> 
                        <img className='sponsorsImg1' src={GlennAndersonAirLogo}/>
                    </div>

                    <div className='sponsors-f-1'> 
                    <img className='sponsorsImg1' src={ParkRidgeAnimal}/>
                    </div>
                </div>

                <div className='sponsors-page-container'>
                    <div className='sponsors-f-1'> 
                        <img className='sponsorsImg1' src={BNCEstates}/>
                    </div>

                    <div className='sponsors-f-1'> 
                    <img className='sponsorsImg1' src={DVSLogo}/>
                    </div>
                </div>

                <div className='sponsors-page-container'>
                    <div className='sponsors-f-1'> 
                        <img className='sponsorsImg1' src={ShutterbugPhoto_Booth}/>
                    </div>

                    <div className='sponsors-f-1'> 
                    <img className='sponsorsImg1' src={WDI2}/>
                    </div>
                </div>

                <div className='sponsors-page-container'>
                    <div className='sponsors-f-1'> 
                        <img className='sponsorsImg1' id='img-alt-1' src={KEAAdvertising}/>
                    </div>

                    <div className='sponsors-f-1'> 
                    <img className='sponsorsImg1' id='img-alt-1' src={LaurenAndersonAssociates}/>
                    </div>
                </div>


                <div className='sponsors-page-container'>
                    <div className='sponsors-f-1'> 
                        <h2>Richard Scull</h2>
                    </div>

                    <div className='sponsors-f-1'> 
                        <h2>Jaclyn Elizabeth Enterprises</h2>
                    </div>
                </div>
            </div>
            </React.Fragment>
        )
    }
}
