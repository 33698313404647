import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

export default function MobileMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

//   const Title = {
//   width: '35px',
//   height: '4px',
//   backgroundColor: 'black',
//   margin: '1.5px 0'
//   }

//   const Cont = {
//     display: 'flex',
//     flexDirection: 'column'
//     }

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
      Menu
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem><a id='test-ref' href='/'>Home</a></MenuItem>
        <MenuItem><a id='test-ref'  href='/about'>About</a></MenuItem>
        {/* <MenuItem><a href='/fund'>Fundraiser</a></MenuItem> */}
        {/* <MenuItem><a href="/golf-outing">Golf Outing</a></MenuItem> */}
        <MenuItem><a id='test-ref'  href='/events'>Events</a></MenuItem>
        <MenuItem><a id='test-ref'  href="/donations">Donate</a></MenuItem>
      </Menu>
    </div>
  );
}