import React, { Component } from 'react';
import axios from 'axios';
import './donationStyles.css'

export default class DonatePage extends Component {

    render() {
        return (
            <div className='body-donation'>
                <div className="donate-row">
                <div className="col-75">
                    <div className="container-d">
                    <form action="/action_page.php">
                    
                        <div className="donate-row">
                        <div className="col-50">
                            <h3>Billing Address</h3>
                            <div className="donate-row">
                            <div className="col-50">
                                <label className="label-donations" for="state">First Name</label>
                                <input className='input-donation-text' type="text" id="state" name="state" placeholder="NY"/>
                            </div>
                            <div className="col-50">
                                <label className="label-donations" for="zip">Last Name</label>
                                <input className='input-donation-text' type="text" id="zip" name="zip" placeholder="10001"/>
                            </div>
                            </div>
                            <label className="label-donations" for="email"><i class="fa fa-envelope"></i> Email</label>
                            <input className='input-donation-text' type="text" id="email" name="email" placeholder="john@example.com"/>
                            <label className="label-donations" for="adr"><i class="fa fa-address-card-o"></i> Address</label>
                            <input className='input-donation-text' type="text" id="adr" name="address" placeholder="542 W. 15th Street"/>
                            <label className="label-donations" for="city"><i class="fa fa-institution"></i> City</label>
                            <input className='input-donation-text' type="text" id="city" name="city" placeholder="New York"/>

                            <div className="donate-row">
                            <div className="col-50">
                                <label className="label-donations" for="state">State</label>
                                <input className='input-donation-text' type="text" id="state" name="state" placeholder="NY"/>
                            </div>
                            <div className="col-50">
                                <label className="label-donations" for="zip">Zip</label>
                                <input className='input-donation-text' type="text" id="zip" name="zip" placeholder="10001"/>
                            </div>
                            </div>
                        </div>

                        <div className="col-50">
                            <h3>Payment</h3>
                            <label className="label-donations" for="ccnum">Credit card number</label>
                            <input className='input-donation-text' type="text" id="ccnum" name="cardnumber" placeholder="1111-2222-3333-4444"/>
                            <label className="label-donations" for="expmonth">Exp Month</label>
                            <input className='input-donation-text' type="text" id="expmonth" name="expmonth" placeholder="September"/>
                            <div className="donate-row">
                            <div className="col-50">
                                <label className="label-donations" for="expyear">Exp Year</label>
                                <input className='input-donation-text' type="text" id="expyear" name="expyear" placeholder="2018"/>
                            </div>
                            <div className="col-50">
                                <label className="label-donations" for="cvv">CVV</label>
                                <input className='input-donation-text' type="text" id="cvv" name="cvv" placeholder="352"/>
                            </div>
                            </div>
                        </div>
                        
                        </div>
                        <input type="submit" value="Place Donation" className="btn-donations"/>
                    </form>
                    </div>
                </div>
            </div>

            </div>
        )
    }
}
