import React, { Component } from 'react'

export default class ECardTwo extends Component {
    render() {

        var sectionStyle = {
            backgroundImage: "url(this.props.eventsObject.picSrc",
          }

    return (
        <div className="band2">
            {this.props.eventsObject.map((data) => {
                return(
                    <div class="item-2">
                        <a href={data && data.live === true ? "/events#gotoevents" : null} class="card">
                            <div class="thumb" style={{backgroundImage: `url(${data.picSrc}` }}></div>
                            <article>
                            <h3>{data.name}</h3>
                                <p>
                                {data.Description}
                                </p>
                                    <div> 
                                        <ul>
                                            <li>Date: {data.date.Month} {data.date.Day}, {data.date.Year}</li>
                                            <li>Time: {data.date.Time}</li>
                                        </ul>
                                    </div>
                            </article>
                        </a>
                    </div>
                    )
                })}
        </div>
        )
    }
}
