import React, { Component } from 'react';
import AcuraRamseyLogo from '../../Images/sponsorLogo/AcuraofRamsey_Logo.png';
import AutohausLogo from '../../Images/sponsorLogo/Autohaus_Logo.jpg';
import BNCEstates from '../../Images/sponsorLogo/BNCEstates_Logo.png';
import CCBF from '../../Images/sponsorLogo/CCBF_Logo.PNG';
import ConnectOneBank from '../../Images/sponsorLogo/ConnectOneBank_Logo.jpg';
import DVSLogo from '../../Images/sponsorLogo/DVS_Logo22.png';
import GlennAndersonAirLogo from '../../Images/sponsorLogo/GlennAndersonAir_Logo.png';
import HondaFinancialServices from '../../Images/sponsorLogo/HondaFinancialServices_Logo.gif';
import KEAAdvertising from '../../Images/sponsorLogo/KEAAdvertising_Logo.jpg';
import LaurenAndersonAssociates from '../../Images/sponsorLogo/LaurenAndersonAssociates_Logo.bmp';
import MahwahHondaLogo from '../../Images/sponsorLogo/MahwahHonda_Logo.jpg';
import ParkRidgeAnimal from '../../Images/sponsorLogo/ParkRidgeAnimal_Logo2.jpeg';
import PNCBank from '../../Images/sponsorLogo/PNCBank_Logo.jpg';
import RMG from '../../Images/sponsorLogo/RMG_Logo.jpg';
import ShutterbugPhoto_Booth from '../../Images/sponsorLogo/ShutterbugPhoto_Booth_Logo.jpg';
import WDI2 from '../../Images/sponsorLogo/WDI2_Logo.png';
import Withum from '../../Images/sponsorLogo/Withum_Logo.png';
import './aboutStyles.css';


export default class SponsorsPage extends Component {

    returnTheLogos = () => {
        let arrLogo = [
        AcuraRamseyLogo, 
        MahwahHondaLogo, 
        //cohreznik
        ConnectOneBank, 
        PNCBank, 
        AutohausLogo, 
        Withum,
        HondaFinancialServices, 
        RMG, 
        CCBF, 
        GlennAndersonAirLogo,
        ParkRidgeAnimal, 
        BNCEstates,
        LaurenAndersonAssociates, 
        KEAAdvertising, 
        DVSLogo, 
        ShutterbugPhoto_Booth, 
        WDI2
    ]

        return(
            <div>
                {arrLogo.map((x) => {
                    return (
                        <img className='cont-img' src={x}/>
                    )
                })}
            </div>
        )
    }
    render() {
        return (
            <React.Fragment>
                 <div className='sponsors-container'>
                    <div>
                        <h1> Sponsors</h1>
                    </div>

                    <p>Thank you to our sponsors, lorum ipsum lorum ipsum lorum ipsum lorum ipsum........</p>

{/* <img src={WDI2}/>
<img src={Withum}/> */}

{this.returnTheLogos()}

                </div>
            </React.Fragment>
        )
    }
}
