import React, { Component } from 'react'

export default class footer extends Component {
    render() {
        return (
            <footer className="page-footer">
            <small>© 2024 Alan J Anderson Foundation is a 501(c)(3) nonprofit organization registered in the United States.</small>
            <ul>
              <li>
                <a href="" target="_blank">
                  <i className="icon ion-logo-facebook"></i>
                </a>
              </li>
              <li>
                <a href="" target="_blank">
                  <i className="icon ion-logo-twitter"></i>
                </a>
              </li>
              <li>
                <a href="" target="_blank">
                  <i className="icon ion-logo-linkedin"></i>
                </a>
              </li>
              <li>
                <a href="" target="_blank">
                  <i className="icon ion-logo-github"></i>
                </a>
              </li>
            </ul>
          </footer>
        )
    }
}
