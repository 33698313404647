import React, { Component } from 'react'
import GolfCourse2 from '../../Images/NewYork_CC.png';
import EventsObject from '../../data_objects/eventsObject';
import Brochure from '../../pdf/2024_Golf_Brochure.pdf';
import { Button } from '@material-ui/core';
import './eventsCard.css';

export default class EventPageCrad extends Component {
    render() {
        return (

          <React.Fragment>
        <div className='e-object2'>
        <div className="band3">
                    <div class="item-3">
                        <a class="card3">
                            <div id='gotoevents' class="thumb3" style={{backgroundImage: `url(${GolfCourse2}` }}></div>
                            <article>
                            <h2>5th Annual Golf Outing</h2>
                                <p>
                                </p>
                                    <div className="sec-1"> 
                                        <ul>
                                            <li><b>Address:</b> 103 Brick Church Road, New Hempstead, NY 10977 </li>
                                            <li><b>Venue:</b> New York Country Club</li>
                                            <li><b>Date:</b> September 23rd, 2024</li>
                                            <li><b>Registration:</b> 11:30AM</li>
                                            <li><b>Shotgun Start:</b> 1PM</li>
                                        </ul>
                                    </div>

                                    <div className ='sectionOps'>
                                      <h3>Ticket Options:</h3>
                                      <div className='ops'>
                                        <div className='ops2'>
                                              <h3>Golfing</h3>
                                              <li><b>Includes:</b>
                                                <li>- Golf and BBQ Lunch</li>
                                                <li>- Buffet Dinner and Open Bar</li>
                                              </li>
                                              <div><a class="brochure" href={Brochure} download><b><i>View Brochure</i></b></a></div>
                                              <a href='/golf-outing'>
                                                <button className="btn signUp-btn">
                                                  Sign Up
                                                </button>
                                              </a>
                                              {/* <div>Registration for golf is closed. Thank you for the support. Please contact us to be put on a waitlist.</div> */}
                                              {/* <div><i>Registration will open shortly. Thank you.</i></div> */}
                                        </div>

                                        <div className='ops2'>
                                              <h3>Dinner</h3>
                                              <li><b>Includes:</b> 
                                                  <li>Buffet Dinner and Open Bar</li>                                              
                                              </li>
                                            <div><a class="brochure" href={Brochure} download><b><i>View Brochure</i></b></a></div>
                                            <a href='/golf-outing'>
                                              <button className="btn signUp-btn">
                                                Sign Up
                                              </button>
                                            </a>
                                        </div>
                                      </div>

                                  {/* <div className='closed-reg-sec'>
                                    <div>Registration for the 4th annual Alan J. Anderson Foundation Memorial Golf Outing is now closed.</div>
                                    <div>Thank you to our very generous sponsors, donors, and supporters for all your support. We look forward to seeing you next year!</div>
                                    <div>For additional information or to donate a raffle item, please email alanjandersonfoundation@gmail.com.</div> */}

                                    {/* <button className="btn signUp-btn"><a href='/donations'>Donate</a></button> */}
                                  {/* </div> */}

                                    </div>
                            </article>
                        </a>
                    </div>
        </div>
        </div>
          </React.Fragment>
        )
    }
}
