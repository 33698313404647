import React, { Component } from 'react';
import AboutCard from './aboutCard';
import BoardCard from './boardCard';
import BoardCardTwo from './boardCtwo';
import { Button } from '@material-ui/core';
import SponsorPage from './SponsorsPage';
import SponsorPageTwo from './SponsorsPageTwo';

export default class About extends Component {
    render() {
        return (

            <React.Fragment>
                <AboutCard/>

                <div className='board-container'>

                <div className="board-container">
                    <div>
                        <h3>Mission Statement</h3>
                        <div className="mission-statement">
                            <p>The Alan J Anderson Foundation is a 501(c)(3) charitable non-profit organization dedicated to 
                                honoring Alan’s legacy and perpetuating his values. To accomplish its purpose, the Foundation 
                                financially supports charitable organizations devoted to eliminating cancer and assisting those 
                                affected by cancer and provides scholarships to outstanding student athletes in the New Jersey area.</p>
                        </div>
                    </div>
                </div>

                    <div>
                        <h1> Meet The Board </h1>
                    </div>
                    <BoardCardTwo/>
                </div>

                <SponsorPageTwo/>

            </React.Fragment>
        )
    }
}
