import Friedman from '../Images/HeadShots/FriedOne.png';
import Christopher from '../Images/HeadShots/chrisOne.jpg';
import Richard from '../Images/HeadShots/RichTwo.png';
import Dana from '../Images/HeadShots/danaThree.png';
import Jill from '../Images/HeadShots/JillThree.png';
import Lauren from '../Images/HeadShots/LaurenOne.jpg';
import MikeM from '../Images/HeadShots/MikeM4.png';
import MikeP from '../Images/HeadShots/MikeP.png';

const boardObj = [
    {
        "name": "Robert Friedman",
        "title": "President, Co-Chair",
        "pic": Friedman
    },
    {
        "name": "Christopher Anderson",
        "title": "Vice President",
        "pic": Christopher
    },
    {
        "name": "Dana Minardi",
        "title": "Co-Chair, Co-Treasurer",
        "pic": Dana
    },
    {
        "name": "Richard Anderson",
        "title": "Co-Treasurer",
        "pic": Richard
    },
    {
        "name": "Michael Minardi",
        "title": "Chief Information Officer",
        "pic": MikeM
    },
    {
        "name": "Jillian Anderson",
        "title": "Secretary",
        "pic": Jill
    },
    {
        "name": "Lauren Anderson",
        "title": "Director",
        "pic": Lauren
    },
    {
        "name": "Michael Piccolo",
        "title": "Director",
        "pic": MikeP
    }
]

export default boardObj;