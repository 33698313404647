import React, { Component } from 'react'
// import '../About/aboutStyles.css'


export default class EventCard extends Component {

    render() {
        return (
            <div className='event-container'>
            {this.props.eventsObject.map((data) => {
                return(
                <div className= "event-card">
                <a href='/events'>
                <div>
                <img className="event-img" src="https://picsum.photos/200" />
                </div>

                <h3>{data.name}</h3>
                <p>
                {data.Description}
                </p>

                    <div> 
                        <ul>
                        <li>Date: {data.date.Month} {data.date.Day+'nd'}, {data.date.Year}</li>
                        <li>Time: {data.date.Time}</li>
                        {/* <li>Event Options: </li>
                            <ul>
                                <li>Golfing</li>
                                <li>Price: {data.eventOptions.Golfing.Price}</li>
                                <li></li>
                            </ul> */}
                        </ul>
                    </div>
                    </a>
                </div>
            )
        })}
        </div>

        )
    }
}
