import React, { Component } from 'react';
import axios from 'axios';
import { asCurrencyValue, purePriceValue } from '../../utils/pipes';
import { TextField, Grid, makeStyles, Button } from '@material-ui/core';
import './form.css';

export default class SignUpForm extends Component {

    state = {
        first: '',
        last: '',
        company: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        zip: '',
        stateOf: "",
        message: '',
        thankYou: '',
        eventType: '',
        amount: '',
        creditCard: '',
        expirationDate: '',
        securityCode: '',
        apiLoginId: '2tQMHyz323a',
        apiKey: '7Rp73M2q49kQTRxE',
        transactionResponse: ''
      }

      testEmailEvent = () => {
        axios.get('/api/donationz/donate_test_1').then((result) => {
            console.log("result...", result)
          }).catch((error) => {
             console.log('err', error);
          })
      }

      purchaseTicket = (event) => {
        this.setState({ transactionResponse: 'Loading'})

        event.preventDefault();
       const sendPaymentPayload = {
            donation_name: this.state.first,
            donation_last: this.state.last,
            donation_email: this.state.email,
            donation_phone: this.state.phone,
            donation_amount: this.state.amount,
            donation_paid: true,
            donation_address: this.state.address,
            donation_city: this.state.city,
            donation_state: this.state.stateOf,
            donation_zipCode: this.state.zip,
            donation_message: `This purchase is for a ticket for the: ${this.state.eventType}`,
            donation_anonymous: false,
            event_type: "Donation",
            card_number: this.state.creditCard,
            expiration_date: this.state.expirationDate,
            card_code: this.state.securityCode,
            company_name: this.state.company
          }

          axios.post('/api/donationz/donate', sendPaymentPayload).then((result) => {
            this.setState({ transactionResponse: result.data })
          }).catch((error) => {
             console.log('err', error);
          })

      }
      handleInputChange = event => {
        // Pull the name and value properties off of the event.target (the element which triggered the event)
        const { name, value } = event.target;
        // Set the state for the appropriate input field
        this.setState({
          [name]: value
        });
      };

      handleDropDownChange = event => {
        this.setState({ stateOf: event.target.value });
      }

      handleRadioChange = (event) => {
        this.setState({
          eventType: event.target.value,
        });
      }

      handleErrorMessage = () => {
        if(this.state.transactionResponse === 'Error') {
            return (
                <div style={{"color": "red", "padding": "1%"}}>
                Error processing your payment. Please check your credentials and try again.
                </div>
            )
        } else {
            return;
        }
      }

      eventChoices = () => {
        //Golf Dinner
            return (
              <div>
                <li>
                    <label className="input-label" for="amount">Donation Amount: <b>${asCurrencyValue(this.state.amount)}</b></label>
                        <input className="input-input" type="text" id="amount" 
                        placeholder="Please enter your donation amount"
                        name="amount" onChange={this.handleInputChange}
                        required/>
                </li>
                    <li>
                      <label className="input-label" for="creditCard">Card Number</label>
                          <input className="input-input" type="text" id="creditCard" 
                          placeholder="Please enter your card number"
                          name="creditCard" onChange={this.handleInputChange}
                          required/>
                  </li>
                  <li>
                      <label className="input-label" for="expirationDate">Expiration Date</label>
                          <input className="input-input" type="text" id="expirationDate" 
                          placeholder="MM/YYYY"
                          name="expirationDate" onChange={this.handleInputChange} 
                          required/>
                  </li>
                  <li>
                      <label className="input-label" for="securityCode">Security Code</label>
                          <input className="input-input" type="text" id="securityCode" 
                          placeholder="Please enter your Security Code"
                          name="securityCode" onChange={this.handleInputChange} 
                          required/>
                  </li>
  
                  <button className='btn sign-up-form-btn' onClick={(event) => this.purchaseTicket(event)}>Donate</button>
              </div>
            )
  }

  paymentChoices = () => {
    //Golf Dinner
        return (
        <Grid container>
            <Grid className='form-type-1' item sm={6} lg={6} xs={12}>
                <TextField
                type="number"
                    fullWidth
                    required
                    id="outlined-required"
                    label="Donation Amount"
                    defaultValue=""
                    variant="outlined"
                    name='amount'
                    onChange={this.handleInputChange}
                    />
            </Grid>

            <Grid className='form-type-1' item sm={6} lg={6} xs={12}>
                <TextField
                    fullWidth
                    required
                    id="outlined-required"
                    label="Card Number"
                    defaultValue=""
                    variant="outlined"
                    name='creditCard'
                    onChange={this.handleInputChange}
                    />
            </Grid>

            <Grid className='form-type-1' item sm={6} lg={6} xs={12}>
                <TextField
                    fullWidth
                    required
                    id="outlined-required"
                    label="Expiration Date"
                    defaultValue="MM/YYYY"
                    variant="outlined"
                    name='expirationDate'
                    onChange={this.handleInputChange}
                    />
            </Grid>

            <Grid className='form-type-1' item sm={6} lg={6} xs={12}>
                <TextField
                    fullWidth
                    required
                    id="outlined-required"
                    label="Security Code"
                    defaultValue=""
                    variant="outlined"
                    name='securityCode'
                    onChange={this.handleInputChange}
                    />
            </Grid>
            <Grid sm={12}>
                <Button id='m-ui-signup-btn' onClick={(event) => this.purchaseTicket(event)}>DONATE</Button>
            </Grid>
        </Grid>
        )
}

    render() {
        return (
        <div className="signUp-container">
            <section className="signUp-section2">
                <div className="signUp-box">
                <form className="signUp-form">
                    <div className="signUp-inner">
                        <h2>Donations Page</h2>
                        <span>All donations go towards cancer research, and scholarship programs for student athletes</span>
                    </div>


        <Grid container>
            <Grid className='form-type-1' item sm={6} lg={6} xs={12}>
                <TextField
                    fullWidth
                    required
                    id="outlined-required"
                    label="First Name"
                    defaultValue=""
                    variant="outlined"
                    name='first'
                    onChange={this.handleInputChange}
                    />
            </Grid>

            <Grid className='form-type-1' item sm={6} lg={6} xs={12}>
                <TextField
                    className='form-type-1'
                    required
                    fullWidth
                    id="outlined-required"
                    label="Last Name"
                    defaultValue=""
                    variant="outlined"
                    name='last'
                    onChange={this.handleInputChange}
                    />
            </Grid>

            <Grid className='form-type-1' item sm={12} lg={6} xs={12}>
                <TextField
                    required
                    className='form-type-1'
                    fullWidth
                    id="outlined-required"
                    label="Email"
                    defaultValue=""
                    variant="outlined"
                    name='email'
                    onChange={this.handleInputChange}
                    />
            </Grid>

            <Grid className='form-type-1' item sm={12} lg={6} xs={12}>
                <TextField
                    required
                    className='form-type-1'
                    fullWidth
                    id="outlined-required"
                    label="Phone Number"
                    defaultValue=""
                    variant="outlined"
                    name='phone'
                    onChange={this.handleInputChange}
                    />
            </Grid>

            <Grid className='form-type-1' item sm={12} lg={12} xs={12}>
                <TextField
                    className='form-type-1'
                    fullWidth
                    id="outlined-required"
                    label="Company"
                    defaultValue=""
                    variant="outlined"
                    name='company'
                    onChange={this.handleInputChange}
                    />
            </Grid>


            <Grid className='form-type-1' item sm={12} lg={12} xs={12}>
                <TextField
                    required
                    className='form-type-1'
                    fullWidth
                    id="outlined-required"
                    label="Address"
                    defaultValue=""
                    variant="outlined"
                    name='address'
                    onChange={this.handleInputChange}
                    />
            </Grid>

            <Grid className='form-type-1' item sm={12} lg={6} xs={12}>
                <TextField
                    required
                    className='form-type-1'
                    fullWidth
                    id="outlined-required"
                    label="City"
                    defaultValue=""
                    variant="outlined"
                    name='city'
                    onChange={this.handleInputChange}
                    />
            </Grid>

            <Grid className='form-type-1' item sm={12} lg={6} xs={12}> { /* remember to add the correct dd from mui */ }
                <TextField
                    required
                    className='form-type-1'
                    fullWidth
                    id="outlined-required"
                    label="State"
                    defaultValue=""
                    variant="outlined"
                    name='state'
                    onChange={this.handleInputChange}
                    />
            </Grid>

            <Grid className='form-type-1' item sm={12} lg={6} xs={12}>
                <TextField
                    required
                    className='form-type-1'
                    fullWidth
                    id="outlined-required"
                    label="Zip Code"
                    defaultValue=""
                    variant="outlined"
                    name='zip'
                    onChange={this.handleInputChange}
                    />
            </Grid>

        </Grid>
                    {/* <div className='input-fields'>
                        <li>
                        <label className="input-label" for="phone">Choose State: {this.state.stateOf}</label>
                        <select className="state-dropdown" onChange={this.handleDropDownChange}>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option selected value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </select>
                        </li> */}
            { this.state.transactionResponse !== 'Success' ?
                <>
                            {this.paymentChoices()}

                    {this.state.transactionResponse === 'Loading' ?
                    <>
                        <h1>Loading...</h1>
                        <div class="spin"></div>
                    </> :
                        <div>
                            {this.handleErrorMessage()}
                        </div>
                    }       
                    </>

           : <div style={{ "padding": "3%"}}> 
                <div> Thank you for donating! </div> 
           </div>
           }
                    {/* </div> */}
                    </form>
                </div>
            </section>
        </div>
        )
    }
}
